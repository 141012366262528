module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TMTG","short_name":"TMTG","start_url":"/","display":"minimal-ui","icons":[{"src":"src/images/icon-57x57.png","type":"image/png","sizes":"57x57"},{"src":"src/images/icon-64x64.png","type":"image/png","sizes":"64x64"},{"src":"src/images/icon-72x72.png","type":"image/png","sizes":"72x72"},{"src":"src/images/icon-114x114.png","type":"image/png","sizes":"114x114"},{"src":"src/images/icon-120x120.png","type":"image/png","sizes":"120x120"},{"src":"src/images/icon-144x144.png","type":"image/png","sizes":"144x144"},{"src":"src/images/icon-180x180.png","type":"image/png","sizes":"180x180"},{"src":"src/images/icon-192x192.png","type":"image/png","sizes":"192x192"},{"src":"src/images/icon-512x512.png","type":"image/png","sizes":"512x512"}],"icon":"src/images/icon-64x64.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d216c84faa6943dadcf8487ad4c45fd1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
